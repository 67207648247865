<template>
  <div id="login-content">
    <div class="login-content__login-box box-shadow-1">
      <div class="login-content__fill-Img-container">
        <img src="@/assets/loginfill.jpg" class="login-content__vege-img" alt="" />
      </div>
      <b-form v-on:submit.prevent="logon" id="loginform">
        <img src="@/assets/XFarmsLogo.png" class="login-content__login-form__logo" alt="" />
        <h4 class="">Login your account</h4>
        <b-form-input
          id="user"
          class="login-content__login-form__credentials"
          type="text"
          v-model="login.username"
          placeholder="username"
          required
        >
        </b-form-input>

        <b-form-input
          id="pass"
          class="login-content__login-form__credentials"
          type="password"
          v-model="login.password"
          placeholder="password"
          required
        >
        </b-form-input>
        <hr class="login-content__credentials" />

        <b-button class="login-content__login-form__submitbtn" type="submit" variant="primary"
          >LOGIN</b-button
        >

        <!-- Link to forgot password -->
        <router-link
          :to="{ name: 'forgotpass' }"
          exact
        >
          <div style="padding-top: 10px;">
            Forgot Password
          </div>
        </router-link>

        <b-toast
          id="loginErr"
          title="Login Error"
          static
          no-auto-hide
          variant="danger"
          class="login-content__login-form__displayerr"
        >
          Incorrect Username or password
        </b-toast>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "../router";

export default {
  name: "Login",
  data() {
    return {
      login: { username: "", password: "" },
    };
  },
  methods: {
    logon(evt) {
      evt.preventDefault();
      axios
        .post(`/login`, this.login)
        .then((response) => {
          if (response.data.reset) {
            router.replace({ name: "passwordset" });
          } else {
            console.log(
              `Logged in ${JSON.stringify(response.data.user.username)}`
            );
            // push to store
            this.$store.commit('login', response.data.user)
            router.push("/");
            this.$parent.isloggedIn();
          }
        })
        .catch((e) => {
          console.log(`Login error: ${e.response.data.message}`);
          this.makeToast();
        });
    },
    makeToast() {
      this.$bvToast.show("loginErr");
    },
  },
};
</script>

<style>
#login-content {
  width: 100%;
  background-color: white;
  padding : 25px 70px ;
}
.login-content__login-box {
  height: 100%;
  margin: 2vh;
  border-radius: 20px;
  display: flex;
}
.login-content__login-box * {
  flex: 1 1 0px;
}
#loginform {
  margin-top: 5%;
  /* margin: 5% 10% 5% 10%; */
  padding: 5% 10%;
}

.login-content__login-form__logo {
  width: 50%;
  margin: 20px;
}
.login-content__login-form__credentials {
  margin: 20px auto;
  width: 100%;
}
.login-content__login-form__submitbtn {
  width: 100%;
  background-color: rgb(17, 202, 17);
  border: none;
}
.login-content__login-form__submitbtn:hover {
  background-color: green;
}
.login-content__login-form__displayerr {
  margin: 10px auto;
}
.login-content__fill-Img-container {
  width: 150vw;
  overflow: hidden;
}
.login-content__vege-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
}

@media only screen and (max-width: 425px) {
  /* For mobile phones: */
  .login-content__fill-Img-container {
    display: none;
  }

  #login-content {
    width: 100%;
    background-color: white;
    padding: 0;
    padding : 10px 20px ;
  }
}
</style>
